import {
  CafeDetailOverviewProps,
  DetailPageOverviewProps,
} from "@bluebottlecoffee/design-system/components";
import { Hours } from "@bluebottlecoffee/design-system/components/lib/types";
import { toHTML } from "@portabletext/to-html";
import { format, Locale } from "date-fns";
import { AddressAndHoursProps } from "@bluebottlecoffee/design-system/components/modules/DetailPageOverview/AddressAndHour";
import { getCafeId } from "../utils";
import { getLocale } from "../integrations/date-fns/get-locale";
import {
  TranslationGroupSanityType,
  toCopyObject,
} from "../sanity/translation-group-queries";
import { keyof } from "../sanity/utils";
import {
  Cafe,
  DetailPageOverview as DetailPageOverviewSchema,
} from "../sanity-schema";
import { toBulletedKeyValue } from "./bulleted-key-value";
import { CafeHoursModel, useCafeHours } from "../integrations/op";
import { getUrlSearchFormat } from "./cafe-card";

type HoursTransformerProps = {
  cafeHours?: CafeHoursModel[];
  locale: Locale;
};

export function toHoursProps({
  cafeHours,
  locale,
}: HoursTransformerProps): Hours[] {
  const displayTime = (date: Date) => format(date, "h:mm a", { locale });

  const hours = cafeHours?.map(({ date, opens, closes }) => ({
    day: date.toLocaleString(locale.code, { weekday: "long" }),
    // only show the open and close times if they are defined
    ...(opens && { [keyof<Hours>("open")]: displayTime(opens) }),
    ...(closes && { [keyof<Hours>("close")]: displayTime(closes) }),
  }));

  return hours ?? [];
}
type BrewGuideDetailPageOverviewTransformerProps = {
  data: DetailPageOverviewSchema;
  region: string;
  lang: string;
};

export function toBrewGuideDetailPageOverviewProps({
  data,
  region,
  lang,
}: BrewGuideDetailPageOverviewTransformerProps): DetailPageOverviewProps {
  return {
    ...(data.bgColor && { bgColor: data.bgColor }),
    detailOverviewCopyBlock: {
      heading: data.detailOverviewCopyBlock.heading[lang],
      description: data.detailOverviewCopyBlock.description
        ? toHTML(data.detailOverviewCopyBlock.description[lang])
        : undefined,
      isH1: false,
    },
    keyValues: data.keyValues
      ? {
          keyValues: data.keyValues.map((kv) =>
            toBulletedKeyValue(kv, region, lang),
          ),
        }
      : { keyValues: [] },
  };
}

interface ToCafeDetailOverviewProps {
  cafe: Cafe;
  lang: string;
  addressAndHoursCopy: TranslationGroupSanityType;
  region: string;
}

export const toCafeDetailOverviewProps = ({
  cafe,
  addressAndHoursCopy,
  lang,
  region,
}: ToCafeDetailOverviewProps): Omit<CafeDetailOverviewProps, "cafeMap"> => {
  const { address, cafeAddressLink, name } = cafe;
  const addressAndHoursCopyData = toCopyObject<AddressAndHoursProps["copy"]>(
    lang,
    addressAndHoursCopy,
  );

  const cafeId = getCafeId(cafe._id);
  const cafeHours = useCafeHours({ cafeId });
  const locale = getLocale({ region, lang });

  return {
    cafeAddress: {
      address: {
        ...address,
        street: address.street,
        locality: address.locality,
        district: address.district,
        postalCode: address.postalCode,
        countryCode: address.countryCode,
        default: true,
      },
      cafeAddressLink:
        cafeAddressLink ||
        `https://www.google.com/maps/search/?api=1&query=Blue+Bottle+Coffee+${getUrlSearchFormat(
          address,
          name[lang],
        )},15z`,
      copy: {
        newWindowWarning: addressAndHoursCopyData.newWindowWarning,
      },
    },
    cafeHours: {
      hours: toHoursProps({ cafeHours, locale }),
      copy: {
        closed: addressAndHoursCopyData.closed,
        description: addressAndHoursCopy.description,
      },
    },
    cafeName: name[lang],
  };
};
