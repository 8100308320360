import {
  Collection,
  useSlideOverDialogContext,
} from "@bluebottlecoffee/design-system/components";
import { ShopCardProps } from "@bluebottlecoffee/design-system/components/ShopCard/ShopCard";
import { FunctionComponent, useCallback } from "react";
import { useHits } from "react-instantsearch";
import {
  Product,
  ProductVariant,
  useCart,
  useProduct,
} from "@chordcommerce/react-autonomy";
import { AlgoliaShopCollectionProduct } from "../../types";
import { toShopCardProps } from "../../../transformers/shop-card";
import { ShopCardButton, ShopCardDetail } from "../../../sanity-schema";
import useVariantPrice from "../../../chord/hooks/components/use-variant-price";
import { SearchDialogCopy } from "../../../../components/SearchDialog";
import useVariantAvailability from "../../../chord/hooks/components/use-variant-availability";
import {
  experiments,
  useExperiment,
  getExperimentBannerColor,
} from "../../../ABTesting";

type SearchResultsProps = SearchDialogCopy & {
  lang: string;
  region: string;
  noResultsShopCards: ShopCardProps[];
  searchIndexName: string;
};

export function isValidShopCardWithoutCollection(
  product: AlgoliaShopCollectionProduct,
): boolean {
  const { shopCard } = product;
  const button: ShopCardButton = shopCard?.button;
  const detail: ShopCardDetail = shopCard?.detail;
  return (
    !!product.name &&
    shopCard &&
    button &&
    !!button.activeText &&
    !!button.inactiveText &&
    detail &&
    !!detail.image &&
    !!detail.tagline
  );
}

export const SearchResults: FunctionComponent<SearchResultsProps> = ({
  lang,
  region,
  searchIndexName,
  noResultsShopCards,
  productSearchCopy,
  shopcardCopy,
}) => {
  const {
    searchResultsCopy,
    searchResultsCopyDefault,
    searchResultsNotFoundCopy,
    searchResultsNotFoundSuggestionCopy,
  } = productSearchCopy;

  let collectionName: string = "";
  let shopCards: ShopCardProps[];

  const {
    hits,
    results: { query },
  } = useHits<AlgoliaShopCollectionProduct>();

  const { setActiveContentType } = useSlideOverDialogContext();
  const { addToCart: chordAddToCart, addSubscription } = useCart();

  const addToCart = useCallback(
    (sku: string, quantity: number) => chordAddToCart({ sku, quantity }),
    [chordAddToCart],
  );

  const { createStockRequest } = useProduct();

  const handleCreateStockRequest = async ({ email, sku }, product: Product) => {
    const productData: Product = {
      ...product,
      variant:
        product?.variant?.sku !== sku
          ? ({
              sku,
            } as ProductVariant)
          : product?.variant,
    };

    await createStockRequest({ email, product: productData });
  };

  const cornerTreatments = useExperiment(experiments.cornerTreatments.id);
  const isCornerTreatmentsABTest =
    experiments.cornerTreatments[cornerTreatments];

  const shopCardsHits = hits.reduce(
    (validProducts: ShopCardProps[], algoliaProduct) => {
      const valid = isValidShopCardWithoutCollection(algoliaProduct);
      if (valid) {
        const shopcard = toShopCardProps({
          data: algoliaProduct,
          lang,
          region,
          props: {
            sourceIndexName: searchIndexName,
            position: algoliaProduct.__position,
            queryID: algoliaProduct.__queryID,
            search: true,
          },
          copy: shopcardCopy.conversionCopy,
          shippingCopy: shopcardCopy.shippingCopy,
        });

        validProducts.push({
          ...shopcard,
          bannerCompactStyle: Boolean(isCornerTreatmentsABTest),
          bannerColor: getExperimentBannerColor(
            shopcard.bannerColor,
            isCornerTreatmentsABTest,
          ),
          useVariantPrice,
          subscribeProduct: addSubscription,
          addToCart,
          createStockRequest: (data: { email: string; sku: string }) =>
            handleCreateStockRequest(data, shopcard.trackShopcardData.product),
          useVariantAvailability,
          // We are using 'clickTraking' to close search dialog
          clickTracking: () => setActiveContentType(null),
          shippingCopy: shopcard.shippingCopy,
        });
      }
      return validProducts;
    },
    [],
  );

  if (hits.length) {
    shopCards = shopCardsHits;
    collectionName = query
      ? `${searchResultsCopy} <b>"${query}"</b>`
      : searchResultsCopyDefault;
  } else {
    shopCards = noResultsShopCards;
    collectionName = query
      ? `${searchResultsNotFoundCopy} <b>"${query}"</b><br/><br/>${searchResultsNotFoundSuggestionCopy}`
      : searchResultsCopyDefault;
  }

  return (
    <Collection
      initMobileGridCols={1}
      name={collectionName}
      shopCards={shopCards}
    />
  );
};
