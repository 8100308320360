import { Color } from "@bluebottlecoffee/design-system/components/lib/types";

type Experiment<T> = {
  id: string;
  [key: number]: T;
};

type Experiments = {
  sortBy: Experiment<boolean>;
  searchBarFunctionality: Experiment<boolean>;
  cornerTreatments: Experiment<
    false | "bannerCompactStyle" | "bannerCompactStyleAndColors"
  >;
};

export const experiments: Experiments = {
  sortBy: {
    id: "26",
    1: false,
    2: true,
  },
  cornerTreatments: {
    id: "39",
    1: false,
    2: "bannerCompactStyle",
    3: "bannerCompactStyleAndColors",
  },
  searchBarFunctionality: {
    id: "40",
    1: false,
    2: true,
  },
};

export const getExperimentBannerColor = (
  color: Color,
  variant: false | "bannerCompactStyle" | "bannerCompactStyleAndColors",
) => {
  if (variant !== "bannerCompactStyleAndColors") return color;

  switch (color) {
    case "holidayTan2024":
      return "white";
    case "holidayGold2024":
      return "greyLight";
    case "holidayAqua2024":
      return "grey";
    default:
      return color;
  }
};
